import Bread from "../components/bread";
import '../components/services.css'
import ServiceRight from '../components/serversright'
import { Link } from 'react-router-dom';
import Footer from "../components/footer";

export default function ServicePage() {
  return (
    <div>
      <Bread></Bread>
      <div className='service-container'>
        <div className="service-content bgGray">
          <div className='left' style={{padding:0}}>
            <div style={{backgroundColor:'#eef2f8'}} className='service-left-inner flex flex-wrap'>
              <div className='w1-3 plr-15 mb1r'>
                <div className='bgwhite p30'>
                  <Link to='/services-products/ie-pay/' className='service-unit-title'>IE PAY</Link>
                  <p className='service-unit-desc'>IE PAY is a multi-channel and multi-currency payments solution with an in-built digital marketing ...</p>
                  <Link className='readmore' to='/services-products/ie-pay/'>Read More</Link>
                </div>
              </div>
              <div className='w1-3 plr-15 mb1r'>
                <div className='bgwhite p30'>
                  <Link to='/services-products/alipay/' className='service-unit-title'>Alipay</Link>
                  <p className='service-unit-desc'>Alipay is a third-party payment platform provided by Ant Financial Ltd. It is dedicated ...</p>
                  <Link className='readmore' to='/services-products/alipay/'>Read More</Link>
                </div>
              </div>
              <div className='w1-3 plr-15 mb1r'>
                <div className='bgwhite p30'>
                  <Link to='/services-products/wechat-pay/' className='service-unit-title'>WeChat Pay</Link>
                  <p className='service-unit-desc'>WeChat Pay is a payment feature integrated into the WeChat APP, and users can ...</p>
                  <Link className='readmore' to='/services-products/wechat-pay/'>Read More</Link>
                </div>
              </div>
              <div className='w1-3 plr-15 mb1r'>
                <div className='bgwhite p30'>
                  <Link to='/services-products/ie-mall/' className='service-unit-title'>IE MALL</Link>
                  <p className='service-unit-desc'>IE MALL is an e-Commerce aggregation that provides cross-border customers with a wide range ...</p>
                  <Link className='readmore' to='/services-products/ie-mall/'>Read More</Link>
                </div>
              </div>
              <div className='w1-3 plr-15 mb1r'>
                <div className='bgwhite p30'>
                  <Link to='/services-products/smart-box/' className='service-unit-title'>Smart Box</Link>
                  <p className='service-unit-desc'>Even if there is no cash register, with access to a wireless network and ... </p>
                  <Link className='readmore' to='/services-products/smart-box/'>Read More</Link>
                </div>
              </div>
            </div>
          </div>
          <div className='right'>
            <ServiceRight></ServiceRight>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
