import { Carousel } from 'antd';
import { Button } from 'antd';
import { Divider } from 'antd';
import { Link } from 'react-router-dom'
import CarouselOne from '../static/img/Slider-2.jpeg';
import CarouselTwo from '../static/img/Slider-3.jpeg';
import CarouselThree from '../static/img/Slider-4.jpeg';

export default function CarouselImg() {
  const carouselOne = {
    backgroundImage: `url("${CarouselOne}")`
  };

  const carouselTwo = {
    backgroundImage: `url("${CarouselTwo}")`
  };

  const carouselThree = {
    backgroundImage: `url("${CarouselThree}")`
  };

  return (
    <Carousel effect="fade" autoplay autoplaySpeed={10000}>
      <div>
        <div className="carousel-img" style={carouselOne}>
          <div className="carousel-content">
            <div className="content">
              <p className="sub-title">We Make A Difference</p>
              <div className="title">Building An Innovative<br/> Financial Technology<br/> Ecosystem</div>
            </div>
            <div className="divider"></div>
            <Link to="/aboutUs">
              <Button className="btn" type="primary" danger>ABOUT US</Button>
            </Link>
          </div>
        </div>
      </div>
      <div>
        <div className="carousel-img" style={carouselTwo}>
          <div className="carousel-content">
            <div className="content">
              <p className="sub-title">Our Mission</p>
              <div className="title">Making Commerce<br/> Better for Everyone</div>
            </div>
            <div className="divider"></div>
            <Link to="/contact-us">
              <Button className="btn" type="primary" danger>CONTACT US</Button>
            </Link>
          </div>
        </div>
      </div>
      <div>
        <div className="carousel-img" style={carouselThree}>
          <div className="carousel-content">
            <div className="content">
              <p className="sub-title">Technology Driven Payments</p>
              <div className="title">Facilitating Online<br/> and Offline Commerce<br/> between Retail Merchants<br/> and Customers</div>
            </div>
            <div className="divider"></div>
            <Link to="/contact-us">
              <Button className="btn" type="primary" danger>CONTACT US</Button>
            </Link>
          </div>
        </div>
      </div>
    </Carousel>
  );
}