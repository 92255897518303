import Bread from "../components/bread";
import Footer from "../components/footer";
import { Row, Col, Button } from 'antd';

const data = [
  {
    title: 'MIE Pay Limited - Anti Bribery and Corruption Policy',
    url: 'https://miepay-public.oss-ap-southeast-2.aliyuncs.com/pdf/MIE%20Pay%20Limited%20-%20Anti%20Bribery%20and%20Corruption%20Policy.pdf'
  },
  {
    title: 'MIE Pay Limited - Audit Risk Committee Charter',
    url: 'https://miepay-public.oss-ap-southeast-2.aliyuncs.com/pdf/MIE%20Pay%20Limited%20-%20Audit%20%20Risk%20Committee%20Charter.pdf'
  },
  {
    title: 'MIE Pay Limited - Board Charter',
    url: 'https://miepay-public.oss-ap-southeast-2.aliyuncs.com/pdf/MIE%20Pay%20Limited%20-%20Board%20Charter.pdf'
  },
  {
    title: 'MIE Pay Limited - Code of Conduct',
    url: 'https://miepay-public.oss-ap-southeast-2.aliyuncs.com/pdf/MIE%20Pay%20Limited%20-%20Code%20of%20Conduct.pdf'
  },
  {
    title: 'MIE Pay Limited - Continuous Disclosure and Communications Policy',
    url: 'https://miepay-public.oss-ap-southeast-2.aliyuncs.com/pdf/MIE%20Pay%20Limited%20-%20Continuous%20Disclosure%20and%20Communications%20Policy.pdf'
  },
  {
    title: 'MIE Pay Limited - Related Party Transaction Policy',
    url: 'https://miepay-public.oss-ap-southeast-2.aliyuncs.com/pdf/MIE%20Pay%20Limited%20-%20Related%20Party%20Transaction%20Policy.pdf'
  },
  {
    title: 'MIE Pay Limited - Remuneration and Nomination Committee Charter',
    url: 'https://miepay-public.oss-ap-southeast-2.aliyuncs.com/pdf/MIE%20Pay%20Limited%20-%20Remunerationand%20Nomination%20Committee%20Charter.pdf'
  },
  {
    title: 'MIE Pay Limited - Trading Policy',
    url: 'https://miepay-public.oss-ap-southeast-2.aliyuncs.com/pdf/MIE%20Pay%20Limited%20-%20Trading%20Policy.pdf'
  },
  {
    title: 'MIE Pay Limited - Whistlerlower Policy',
    url: 'https://miepay-public.oss-ap-southeast-2.aliyuncs.com/pdf/MIE%20Pay%20Limited%20-%20Whistlerlower%20Policy.pdf'
  }
];

export default function CorporateGovernance() {
  return (
    <main>
      <Bread />

      <div className="investor-relation">
        <div className="investor-content">
        {
          data.map(item => {
            return  (
              <Row className="item-box">
                <Col md={18} sm={24}>
                  <h2 className="title">{item.title}</h2>
                </Col>
                <Col md={6} sm={24}>
                  <a href={item.url} target="_blank">
                    <Button className="btn" type="primary" size="large">Download</Button>
                  </a>
                </Col>
              </Row>
            )
          })
        }
        </div>
      </div>

      <Footer />
    </main>
  );
}
