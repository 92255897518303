import Bread from "../components/bread";
import '../components/services.css'
import FlowImg from '../static/img/iepay_flow.jpeg'
import QrcodeImg from '../static/img/iepay1.jpeg'
import QuickPayImg from '../static/img/iepay2.jpeg'
import Iepay3Img from '../static/img/iepay3.jpeg'
import Iepay4Img from '../static/img/iepay4.jpeg'
import Iepay5Img from '../static/img/iepay5.jpeg'
import { Link } from 'react-router-dom'
import ServiceRight from '../components/serversright'
import Footer from "../components/footer";

export default function Iepay() {
  return (
    <div>
      <Bread />
      <div className='service-container'>
        <div className="service-content">
          <div className='left'>
            <div className='left-inner'>
              <p className='p10 mb1r'>IE PAY is a multi-channel and multi-currency payments solution with an in-built digital marketing platform that supports businesses of all sizes with low costs. Currently available in New Zealand, IE PAY is an aggregated payment processing solution that allows merchants to accept Alipay, WeChat Pay, UnionPay and POLi at attractive rates.</p>
              <img className='p10 mb1r' style={{width:'100%'}} src={FlowImg}
             alt="" />
             <p className='p10 mb1r'>IE PAY targets bricks and mortar merchants in the tourism, hospitality, luxury, health and beauty sectors and online retailers with an e-commerce platform. End-use app segmentation includes hospitality and tourism, media and entertainment, retail, health care, IT and telecommunication and education.</p>
             <p className='p10 mb1r'>IE PAY distributes the funds to each merchant, net of its fees, at a competitive cost. Participating merchants are currently settled in New Zealand dollars regardless of the currency of the consumer payment on a T+1 toT+4 basis.</p>
             <div className='mt30'>
              <div className='qrcode-content'>
                <div className='w50'>
                  <div className='p10'>
                    <p className='service-title mb1r'>QR Code Payment</p>
                    <p className='desc'>The Vendor generates a transaction QR Code according to the IE PAY Payment Protocol and the Payer goes to “Scan QR Code” in their WeChat, Alipay, UnionPay App in order to complete payment.</p>
                    <p className='desc'>
                      • This mode is applicable to payments made on websites, physical stores, media advertising, or other scenarios
                    </p>
                  </div>
                </div>
                <div className='w50'>
                  <div className='p10'>
                    <img style={{width: '100%'}} src={QrcodeImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className='mt30'>
              <div className='qrcode-content'>
                <div className='w50'>
                  <div className='p10'>
                    <p className='service-title mb1r'>Quick Pay</p>
                    <p className='desc'>The Payer shows their bar code or QR Code on WeChat ,Alipay, UnionPay App Pay page to the Vendor to scan in order to pay directly.</p>
                    <p className='desc'>
                      • This mode applies to offline payment scenarios.
                    </p>
                  </div>
                </div>
                <div className='w50'>
                  <div className='p10'>
                    <img style={{width: '100%'}} src={QuickPayImg} alt="" />
                  </div>
                </div>
              </div>
              <div className='mt30 desc'>
                <p className='service-title' style={{fontSize: '20px'}}>In-App Web-based Payment</p>
                <p>The Payer opens the Vendor’s HTML5 pages on their WeChat and calls the IE PAY payment module via the interface to pay their transaction.</p>
                <p>This mode is applicable in the following scenarios:</p>
                <p>• The Payer enters the Vendor’s official account and completes their payment on the transaction page;</p>
                <p>• The Payer scans the payment QR Code displayed within the Vendor’s page and opens it in a browser to complete their payment.</p>
              </div>
            </div>
            <div className='qrcode-content'>
              <div className='w1-3 p10'>
                <img style={{width: '100%'}} src={Iepay3Img} alt="" />
              </div>
              <div className='w1-3 p10'>
                <img style={{width: '100%'}} src={Iepay4Img} alt="" />
              </div>
              <div className='w1-3 p10'>
                <img style={{width: '100%'}} src={Iepay5Img} alt="" />
              </div>
            </div>
            <div className='mt30'>
              <a className='learn-btn' href='https://mypaynz.com/' target='_blank'>LEARN MORE</a>
            </div>
            </div>
          </div>
          <div className='right'>
            <ServiceRight></ServiceRight>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
