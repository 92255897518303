import Bread from "../components/bread";
import '../components/services.css'
import FlowImg from '../static/img/iepay_flow.jpeg'
import AboutAlipayImg from '../static/img/about_alipay.png'
import ServiceRight from '../components/serversright'

export default function Alipay() {
  return (
    <div>
      <Bread />
      <div className='service-container'>
        <div className="service-content">
          <div className='left'>
            <div className='left-inner'>
              <p className=' mb1r font125'>Alipay is a third-party payment platform provided by Ant Financial Ltd. It is dedicated to provide “Simple, Safe and Convenient” online and mobile payment solutions. Alipay makes people’s lives much easier and more convenient, building on the foundation of real-name registration and trust.</p>
              <p className=' mb1r font125'>Alipay is trusted by more than 500 million active users.</p>
              <p className=' mb1r font125'>Mobile transactions represent 70% of Alipay transactions.</p>
              <img className='p10 mt30' style={{width:'100%'}} src={AboutAlipayImg}
             alt="" />
             <p style={{color: '#004098'}} className='service-title mt30 '>Features</p>
              <ul className='aplipay-ul'>
                <li>
                  <p style={{fontSize: '1.25rem'}} className='bfont'>Safe</p>
                  <p>Safe, reliable collection, utilising the leading technology of the Alipay platform all over the world.</p>
                </li>
                <li>
                  <p style={{fontSize: '1.25rem'}} className='bfont'>Market</p>
                  <p>Great brand effect, face to billions of potential consumers directly.</p>
                </li>
                <li>
                  <p style={{fontSize: '1.25rem'}} className='bfont'>Speed</p>
                  <p>Fast arrival speed at your account, arriving within one to three working days.</p>
                </li>
                <li>
                  <p style={{fontSize: '1.25rem'}} className='bfont'>Exchange Rate</p>
                  <p>The exchange rate is extremely competitive.</p>
                </li>
              </ul>
              <p style={{color: '#004098'}} className='service-title mt30 '>Cross-border Payments</p>
              <img className='p10 mt30' style={{width:'100%'}} src={FlowImg}
             alt="" />
             <p className=' mb1r'>Cross-border payments can help a payer with RMB and a receipt merchant with NZD to make safe and reliable transactions using the unified platform of Alipay. Clients pay through Alipay with RMB and New Zealand merchants obtain payment by the next working day, realizing easy arrival into their account. Merchants can use the Alipay platform to monitor their accounts in real time.</p>
             <p className=' mb1r'>For merchants opening up an Alipay business in New Zealand, MIE Pay will generate an exclusive Alipay two dimensional code for you. The consumers only need to use their own Alipay to scan the two-dimensional code and input the price. Consumers can gain the commodities or service from merchants after MIE Pay confirming the transaction with Alipay within several seconds, resulting in a successful transaction.</p>
            </div>
          </div>
          <div className='right'>
            <ServiceRight></ServiceRight>
          </div>
        </div>
      </div>
    </div>
  );
}
