import Bread from "../components/bread";
import '../components/services.css'
import ServiceRight from '../components/serversright'
import SmartBox1Img from '../static/img/smartbox1.jpeg'
import SmartBox2Img from '../static/img/smartbox2.jpeg'
import SmartBox3Img from '../static/img/smartbox3.jpeg'
import Footer from "../components/footer";

export default function Smartbox() {
  return (
    <div>
      <Bread />
      <div className='service-container'>
        <div className="service-content">
          <div className='left'>
            <div className='left-inner'>
              <p className='p10 mb1r'>Even if there is no cash register, with access to a wireless network and USB power, Smart Box can help merchants traditional payment processes using traditional cashier by upgrading to mobile payments. It provides independent deployment and independent use and can also be used with an external keyboard and scanner.</p>
            <div className='flex'>
              <div className='w50 p10'>
                <p className='service-title mt-50'>One-stop cashier</p>
                <p>The one-stop deployment of WeChat Pay and Alipay and the plug-and-play feature save you the trouble of changing the original cashier system</p>
              </div>
              <div className='w50 p10'>
                <img style={{width:'100%'}} src={SmartBox1Img} alt="" />
              </div>
            </div>
            <div className='flex'>
              <div className='w50 p10'>
                <p className='service-title mt-50'>Independent network access</p>
                <p>WIFI and cable network connections are supported</p>
              </div>
              <div className='w50 p10'>
                <img style={{width:'100%'}} src={SmartBox2Img} alt="" />
              </div>
            </div>
            <div className='flex'>
              <div className='w50 p10'>
                <p className='service-title mt-50'>Fast implementation</p>
                <p>Multiple development interfaces are provided for secondary development and APIs are provided for solution support</p>
              </div>
              <div className='w50 p10'>
                <img style={{width:'100%'}} src={SmartBox3Img} alt="" />
              </div>
            </div>
            <div className='mt30'>
                <p className='m1-2r'>
                  Inspiry invented the offline mobile payments solution— Smart Box. The Smart Box is a self-service QR Code payments terminal.
                </p>
                <p className='m1-2r'>
                  The Inspiry Smart Boxes were first launched at major retail locations in Australia and New Zealand and help SMEs to reach global market.
                </p>
                <p className='m1-2r'>The Smart Boxes allow customers pay with their smartphones by scanning a QR Code in the Alipay and WeChat APPs. It can be easily set-up in any environment having access to the internet and a power cable. The QR Code scanner and interactive payment keypad become functional after a one minute configuration process.</p>
                <p className='m1-2r'>Also, the Smart Boxes cost less than hand-held POS (point of sale) terminals. As a cheaper alternative, these self-service boxes are widely accepted, especially with smaller sized merchants and vendors.</p>
            </div>
            </div>
          </div>
          <div className='right'>
            <ServiceRight></ServiceRight>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
