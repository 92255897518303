import './header.css'
import pointIcon from '../static/icon/point.png'
import PhoneIcon from '../static/icon/phone.png'
import ClockIcon from '../static/icon/clock.png'
import RightArrow from '../static/icon/right_arrow.png'

export default function TopRow() {
  return (
    <div className='top-row header-wraper header-font' style={{backgroundColor: '#2a2e53'}}>
      <div className='horizon-layout header-content'>
        <div className='horizon-layout mr-12 header-headoffice'>
          HEADOFFICE
          <img style={{height:'12px', marginLeft: '6px'}} src={RightArrow} alt="" />
        </div>
        <div className='mr-12'>
          <img className='header-icon' src={pointIcon} alt="" />
          Level 10, 420 George Street, Sydney, NSW 2000, Australia</div>
        <div className='mr-12'>
          <img className='header-icon' src={PhoneIcon} alt="" />
          +61-2-8034-0470</div>
        <div>
          <img className='header-icon' src={ClockIcon} alt="" />
          Mon-Fri: 9:30am-5:30pm</div>
      </div>
    </div>
  )
}
