import { Row, Col, Button, Card } from 'antd';
import { Link } from 'react-router-dom'
import Footer from "../components/footer";
import CarouselImg from '../components/carousel'
import NewsCard from '../components/newsCard'
import HomeImgOne from '../static/img/home-page-handshake.jpeg';
import HomeImgTwo from '../static/img/home-team.jpeg';
import HomeImgThree from '../static/img/home-gain-success-with-us.jpeg';

export default function Home() {
  const contactUs = {
    backgroundImage: `url("${HomeImgThree}")`
  };

  return (
    <div>
      <CarouselImg />
      <main className="home-content">
        <Row className="home-about">
          <Col md={12} sm={24} className="about-content">
            <p className="title">About MIE Pay</p>
            <p className="subtitle">Technology and the internet are transforming commerce</p>
            <p className="detail">MIE Pay is a technology driven payments company that facilitates online and offline commerce between retail merchants and customers.</p>
            <Row>
              <Col md={12} className="statistics">
                <div className="value">1,100+</div>
                <div className="name">Merchants</div>
              </Col>
              <Col md={12} className="statistics">
                <div className="value">1,300+</div>
                <div className="name">Transactions per day</div>
              </Col>
              <Col md={12} className="statistics">
                <div className="value">60K+</div>
                <div className="name">Customers per year</div>
              </Col>
              <Col md={12} className="statistics">
                <div className="value">$60M+ NZD</div>
                <div className="name">in transactions processed per year</div>
              </Col>
            </Row>
          </Col>
          <Col md={12} sm={24}>
            <div style={{width: "100%"}}>
              <img src={HomeImgOne} style={{width: "100%"}}></img>
            </div>
          </Col>
        </Row>
        <div className="home-vision">
          <p className="title">OUR VISION</p>
          <div className="detail">To lead the intelligent consumption of digital payment solutions in Australasia.</div>
        </div>
        <Row className="home-attributes">
          <Col md={12} sm={24}>
            <div style={{width: "100%"}}>
              <img src={HomeImgTwo} style={{width: "100%"}}></img>
            </div>
          </Col>
          <Col md={12} sm={24}>
            <div className="attributes-content">
              <p className="title">KEY ATTRIBUTES</p>
              <p className="subtitle">We host the MIE Pay platform using a mix of co-located and cloud-based servers.</p>
            </div>
            <div className="attributes-list">
              <div className="title"><span className="number">01 </span>Security</div>
              <p className="detail">We have applied to the PCI Security Standards Council for certification as a PCI DSS Level 1 compliant service provider, which is the highest level of compliance available.</p>
              <div className="title"><span className="number">02 </span>Scalability</div>
              <p className="detail">The cloud-based architecture of our platform has been designed to support sudden traffic and order spikes from our merchants.</p>
              <div className="title"><span className="number">03 </span>Reliability</div>
              <p className="detail">Our platform includes servers in geographically dispersed, co-located data centers that are fault-tolerant and ensure that our platform is highly reliable.</p>
              <div className="title"><span className="number">04 </span>Performance</div>
              <p className="detail">We believe that the faster our merchants’ transaction processing appear to be to their customers, the more our merchants will sell.</p>
              <div className="title"><span className="number">05 </span>Deployment</div>
              <p className="detail">The platform is “single branch” software, which means that all our merchants always use the latest version of MIE Pay.</p>
            </div>
          </Col>
        </Row>
        <div className="home-contact-us" style={contactUs}>
          <p className="title">GAIN SUCCESS WITH US</p>
          <p className="detail">The Intelligent Choice<br/> For Retail Business</p>
          <Link to="/contact-us">
            <Button className="btn" type="primary" danger>CONTACT US</Button>
          </Link>
        </div>
        <div className="home-highlight">
          <p className="title">HIGHLIGHTS</p>
          <p className="subtitle">Read Our Latest Articles,& News</p>
          <NewsCard />
        </div>
      </main>

      <Footer />
    </div>
  );
}