import Bread from "../components/bread";
import '../components/services.css'
import ServiceRight from '../components/serversright'
import SmartBox1Img from '../static/img/smartbox1.jpeg'
import SmartBox2Img from '../static/img/smartbox2.jpeg'
import SmartBox3Img from '../static/img/smartbox3.jpeg'
import Footer from "../components/footer";

export default function TermsAndConditions() {
  return (
    <div>
      <Bread />
      <div className='container'>
        <div className="terms">
          <h2 className="font-center">GENERAL TERMS AND CONDITIONS</h2>
          <h3 className="mt30">1. Definitions and Interpretation</h3>
          <div className='plr-30 mt20'>
            <p>(a) The following definitions apply in this Agreement unless inconsistent with the context or otherwise specified:</p>
            <div className='plr-15'>
              <p><b>"Agreement"</b> means this agreement and the Schedules and appendices hereto as may be amended from time to time;</p>
              <p><b>"Affiliate"</b> means: </p>
              <div className='plr-15'>
                <p>(i)	a director, officer, partner, member, manager, executor or trustee of such person; and </p>
                <p>(ii)	any person directly or indirectly controlling, controlled by, or under common control with that person. For purposes of this definition, "control," "controlling," and "controlled" mean having the right to elect a majority of the board of directors or other comparable body responsible for management and direction of a person by contract, by virtue of share ownership or otherwise; and</p>
                <p>(iii) a "Related Company" as that term is defined by section 2(3) of the Companies Act 1993;</p>
              </div>
              <p><b>"Business Day"</b> means a day on which registered banks are open for general banking business, other than a Saturday, Sunday or statutory holiday, in Auckland, New Zealand;</p>
              <p><b>"Commencement Date"</b> means the date on which the Agreement is executed by all parties, and, in the event an electronic version of this Agreement is made available online, the date on which the Merchant clicks the button “I Agree” or a similar button;</p>
              <p><b>"Confidential Information"</b> has the meaning given to that term in clause 16 (Confidential Information and Intellectual Property);</p>
              <p><b>"Five by eight"</b> (5 X 8) technical support means technical support for 8 hours a day (10:00 am to 6 pm) from Monday to Friday.</p>
              <p><b>"GST"</b> means goods and services tax under the Goods and Services Tax Act 1985 (as amended);
"Intellectual Property" means any (i) copyright, patent, know-how, domain names, trademarks, trade names, service marks, brand names, corporate names, logos and designs (whether registered or unregistered) and all goodwill associated therewith; (ii) applications for registration and the right to apply for registration for any of the same; and (iii) all other intellectual property rights and equivalent or similar forms of protection existing anywhere in the world (including its application programming interfaces);</p>
              <p><b>"Merchant's Platform"</b> means the online webpages and platform(s) on which the Merchant, by using the IE Mall Service and/or the IE Shop Service, markets and sells its goods, products and/or services;</p>
              <p><b>"Minimum Service Term"</b> means the minimum term for the provision of a Service as selected by the Merchant on the front page of this Agreement or otherwise as agreed in writing by the Parties;</p>
              <p><b>"Parties"</b> means the Supplier and the Merchant who have signed this Agreement;</p>
              <p><b>"POS Equipment"</b> has the meaning given to that term in clause 7 (POS Rental Service);</p>
              <p><b>"Product"</b> means any and all goods, products and/or services and/or items that the Merchant markets and makes available for sale to any person on the Merchant’s Platform;</p>
              <p><b>"Service Fee"</b> means a fee for the provision of a Service, as set out in the applicable Schedule or the front page of this Agreement and as varied from time to time in accordance with this Agreement;</p>
              <p><b>"Services"</b> means the applicable services as selected by the Merchant on the front page of this Agreement or otherwise as agreed in writing by the Parties, which are to be provided by the Supplier under this Agreement and are described in Schedules 1 to 5 (as applicable);</p>
              <p><b>"Tax"</b> means all governmental, federal, state, provincial, territorial, county, municipal, local or foreign taxes, including but not limited to sales, GST, use, licence, excise, good and services, value added, stamp or transfer taxes, duties, imposts, levies, assessments, tariffs, fees, charges or withholdings of any nature whatsoever levied, imposed, assessed or collected by a taxation authority together with all interest, penalties, fines or other additional amounts imposed in respect thereof;</p>
            </div>
            <p>(b) General provisions of interpretation – In the construction and interpretation of this Agreement unless inconsistent with the context or otherwise specified:</p>
            <div className='plr-15'>
              <p>(i)	references to Background, clauses and Schedules and Appendices are references to background, clauses and schedules and appendices of this Agreement;</p>
              <p>(ii)	references to statutes or statutory provisions include those statutes or statutory provisions as amended, extended, consolidated, re-enacted or replaced from time to time and any orders, regulations, instruments or other subordinated legislation made under them (except to the extent that any amendment enacted after the date of this Agreement would increase or extend the liability of either party);</p>
              <p>(iii)	words importing the singular number include the plural and vice versa, references to any gender include every gender and references to persons include corporations and unincorporated bodies of persons, government or semi-government bodies or agencies or political subdivisions of them;</p>
              <p>(iv)	headings are inserted for convenience only and do not affect the interpretation of this Agreement;</p>
              <p>(v)	references to "NZD", "dollars" and "$" are references to New Zealand dollars unless expressly stated otherwise;</p>
              <p>(vi)	references to "written" and "in writing" include any means of permanent visible representation;</p>
              <p>(vii)	references to time are to New Zealand time;</p>
              <p>(viii)	references to any document include all modifications and replacement documents from time to time; and</p>
              <p>(ix)	in the event of any inconsistency between a provision in these General Terms and Conditions and any other Schedule, the provision in the other Schedule will prevail.</p>
            </div>
          </div>
          <h3 className='mt30'>2. Services</h3>
          <div className='plr-30 mt20'>
            <p>(a) The Supplier will provide the Merchant the applicable Services.  The Parties shall perform and comply with their respective responsibilities set forth in this Agreement.</p>
            <p>(b) As a pre-requisite to the supply of the Services by the Supplier under this Agreement, the Merchant must provide the Supplier with:</p>
            <div className='plr-15'>
              <p>i. a briefing of the Merchant's basic business operations; and</p>
              <p>ii. the Merchant's business email address (but not individual email addresses). </p>
            </div>
            <p>(c) The Merchant grants permission and authorization to the Supplier and its Affiliates and representatives to verify, receive, exchange and obtain business credit and other information in relation to the Merchant on an on-going basis as a continuing obligation herein and for due diligence purposes (including the circumstance of any renewal of this Agreement).</p>
            <p>(d) The Merchant shall use the Services supplied under this Agreement solely in connection with the operation of its retail business and must not use any Service for or in connection with any illegal purpose or activity.</p>
            <p>(e) If the Merchant reports a fault, issue or error with any of the Services and the Supplier is able to verify the existence of the fault, issue or error and that it has not arisen from or caused by any system, software or service not provided by the Supplier, the Supplier will take all reasonable steps (that are within its power and control) to correct the fault, issue or error within a reasonable period of time.</p>
          </div>
          <h3 className='mt30'>3. Merchant Information</h3>
          <div className='plr-30 mt20'>
            <p>(a) The Merchant agrees to provide the Supplier with a copy of the Merchant's valid and effective business licence and certificate of incorporation (if applicable) and relevant background information upon the Supplier's reasonable request.</p>
            <p>(b) The Merchant warrants, represents and certifies that all information contained in this Agreement supplied by the Merchant and any other documentation submitted in support of this Agreement (including, among others, a copy of the Merchant business license and certificate of incorporation (if applicable)), are complete, true and correct.</p>
          </div>
          <h3 className='mt30'>4. IE Mall Service</h3>
          <div className='plr-30 mt20'>
            <p>(a) This clause 4 applies if the Merchant has selected or agreed to receive, or is receiving, the IE Mall Service.</p>
            <p>(b) The Merchant shall ensure that the logo of “IE Mall” shall be displayed in parity with all other forms of payment supported by the Merchant on the Merchant’s Platform where forms of payment are featured for the purchase of good and/or services.</p>
            <p>(c) The Merchant shall ensure that IE Mall is identified as prominently as other payment forms via physical placement on the Merchant’s Platform.</p>
            <p>(d) The Merchant agrees to grant to the Supplier and its Affiliates a non-exclusive, non-transferable, royalty-free licence to use, reproduce, publish, distribute and transmit any marketing materials, proprietary indicia or other similar items containing the Merchant’s Intellectual Property necessary for the Supplier to perform its obligations in respect of the IE Mall Service, including referring to the name of the Merchant in a public announcement as one of the partners using IE Mall Service.</p>
            <p>(e) The Merchant shall be solely responsible and liable for all complaints, goods rejections, claims and disputes arising out of: </p>
            <div className='plr-15'>
              <p>i.	any illegal, false, outdated or incomplete transaction information contained on the Merchant’s Platform; </p>
              <p>ii. any defects or quality issues concerning the Products; or</p>
              <p>iii.	any delay in delivering or supplying, or failure to deliver or supply, any Product.</p>
            </div>
            <p>(f) The Merchant shall not charge the IE Mall visitors any fee for using the IE Mall Service or any other Service (other than the purchase price of the Products).</p>
            <p>(g) It is a prerequisite to the Merchant's receipt of the IE Mall Service that the Merchant be a party to the Supplier’s “Merchant Agreement Relating to Payments” agreement, and in terms whereof the Supplier supplies the Merchant with a payment service, which may include Alipay, WeChat Pay, UnionPay, PoliPay , Visa, and MasterCard payments. If the Merchant Agreement Relating to Payments expires or terminates for any reason, the Supplier may immediately terminate this Service from the scope of this Agreement. </p>
            <p>(h) The Merchant shall not supply any product appearing on Appendix 1 below.</p>
          </div>
          <h3 className='mt30'>5. IE Shop Service</h3>
          <div className='plr-30 mt20'>
            <p>(a) This clause 5 applies if the Merchant has selected or agreed to receive, or is receiving, the IE Shop Service.</p>
            <p>(b) The Merchant agrees to grant to the Supplier and its Affiliates a non-exclusive, non-transferable, royalty-free licence to use, reproduce, publish, distribute and transmit any marketing materials, proprietary indicia or other similar items containing the Merchant’s Intellectual Property necessary for the Supplier to perform its obligations in respect of the IE Shop Service, including referring to the name of the Merchant in a public announcement as one of the partners using IE Shop Service.</p>
            <p>(c) The Merchant shall be solely responsible and liable for all complaints, goods rejections, claims and disputes arising out of: </p>
            <div className='plr-15'>
              <p>i. any illegal, false, outdated or incomplete transaction information contained on the Merchant’s Platform; </p>
              <p>ii. any defects or quality issues concerning the Products; or</p>
              <p>iii. any delay in delivering or supplying, or failure to deliver or supply, any Product.</p>
            </div>
            <p>(d) It is a prerequisite to the Merchant's receipt of the IE Shop Service that the Merchant be a party to the Supplier’s “Merchant Agreement Relating to Payments” agreement, and in terms whereof the Supplier supplies the Merchant with a payment service, which may include Alipay, WeChat Pay, UnionPay, PoliPay, Visa, and MasterCard payments. If the Merchant Agreement Relating to Payments expires or terminates for any reason, the Supplier may immediately terminate this Service from the scope of this Agreement.</p>
            <p>(e) The Merchant shall not supply any product appearing on Appendix 1 below.</p>
          </div>
          <h3 className='mt30'>6. Third Party POS Software Service</h3>
          <div className='plr-30 mt20'>
            <p>(a) This clause 6 applies if the Merchant has selected or agreed to receive, or is receiving, the Third-Party POS Software Service.</p>
            <p>(b) The Merchant acknowledges and agrees that the Supplier is a distributor of the relevant Tap Touch software product used to provide the Service and the Merchant's use of the Tap Touch software product is subject to the terms and conditions located at:  https://www.taptouch.net/terms-and-conditions (Tap Touch User Terms and Conditions).</p>
            <p>(c) The Merchant accepts the Tap Touch User Terms and Conditions and acknowledges and agrees that both the Supplier and Ivida Smart Technologies Pty Ltd may rely on and enforce the Tap Touch User Terms and Conditions against the Merchant.</p>
            <p>(d) The Supplier does not make or give any promises, commitments, representations, warranties or guarantees in relation to the Tap Touch software product beyond or in addition to those expressly set out in the Tap Touch User Terms and Conditions.</p>
            <p>(e) The Supplier may immediately terminate this Service from the scope of this Agreement if the Supplier's agreement with Ivida Smart Technologies Pty Ltd (or its successor) under which the Supplier is authorized to distribute the Tap Touch software product terminates or expires for any reason.</p>
          </div>
          <h3 className='mt30'>7. Third Party POS Hardware Rental Service</h3>
          <div className='plr-30 mt20'>
            <p>(a) This clause 7 applies if the Merchant has selected or agreed to receive, or is receiving, the Third-Party POS Hardware Rental Service.</p>
            <p>(b) The hardware and equipment the Supplier provides the Merchant as part of this Service (POS Equipment) is rented, not sold, to the Merchant. The Merchant does not acquire any ownership or other interest in the POS Equipment.</p>
            <p>(c) Details of the POS Equipment are set out in Schedule 3. Any delivery confirmation, order form or other document which the Supplier provides to the Merchant setting out details of the POS Equipment supplied will be deemed to form part of Schedule 3 and therefore this Agreement.</p>
            <p>(d) The Supplier will install the POS Equipment at the Merchant's retail premises. Where necessary, the Merchant will provide at its cost a suitable installation area and environment with appropriate electrical, internet and telephone connections.</p>
            <p>(e) Prior to using the POS Equipment, the Merchant must check the POS Equipment for any issues or damage. If the Merchant does not notify the Merchant of any damage within 7 days of installation, any damage subsequently notified will be deemed to be damage which occurred while the POS Equipment is at the Merchant's risk and for which the Merchant is responsible.</p>
            <p>(f) Following the Supplier having installed the POS Equipment:</p>
            <div className='plr-15'>
              <p>i.	the Merchant will be responsible for any loss of, or damage to, the POS Equipment;</p>
              <p>ii. the Merchant must take reasonable, prudent steps to ensure that the POS Equipment is not lost, stolen or damaged;</p>
              <p>iii.	the Merchant must notify the Supplier immediately in the event that any of the POS Equipment is lost, stolen or damaged;</p>
              <p>iv. the Merchant must not make any changes or alteration to the POS Equipment;</p>
              <p>v.	the Merchant must not sell, lease, license, mortgage or grant a security interest over the POS Equipment;</p>
              <p>vi. the Merchant must not move the POS Equipment from the location at which it was installed without the Supplier's prior written consent; and</p>
              <p>vii.	the Merchant must use the POS Equipment strictly in accordance with any applicable instructions or guides provided with the POS Equipment or otherwise provided by the Supplier.</p>
            </div>
            <p>(g) If any POS Equipment is damaged, lost or stolen while in the Merchant's possession, the Merchant must (unless the damage was caused by the Supplier, or constitutes fair wear and tear as determined by the Supplier acting reasonably) pay the Supplier on demand for the full cost of replacing or repairing the POS Equipment.</p>
            <p>(h) Unless agreed otherwise by the Supplier in writing, the Merchant must (at the Merchant's cost) ensure the POS Equipment is insured for its full replacement value against risks of loss and damage. The Merchant must ensure that the Supplier is included as an insured party under any such insurance policy. If the Supplier receives any proceeds from such insurance, the Supplier may retain and apply such proceeds as necessary to satisfy any liability of the Merchant under clause 7(g).</p>
            <p>(i) From time to time the Supplier may (at its discretion) replace any POS Equipment with point-of-sale equipment which delivers at least equivalent functionality. Any such replacement equipment will, upon its delivery or installation, be deemed to form part of the POS Equipment provided under this Agreement.</p>
            <p>(j) The Merchant must provide the Supplier with reasonable access to the Merchant's premises during normal operating hours to enable the Supplier to check, repair and/or replace the POS Equipment.</p>
            <p>(k) The Merchant acknowledges and agrees that this Agreement creates a security interest in the POS Equipment under the Personal Property Securities Act 1999. The Supplier may register a financing statement to protect its interest in the POS Equipment. The Merchant will do all things and provide all information and documentation which the Supplier may require to register a financing statement and otherwise protect its interest in the POS Equipment.</p>
            <p>(l) Upon termination of this Agreement or the Third-Party POS Hardware Rental Service, the Merchant must return the POS Equipment to the Supplier in accordance with the Supplier's instructions. If the Merchant fails to do so within 7 days, the Supplier may:</p>
            <div className='plr-15'>
              <p>i.	enter any of the Merchant's premises where the Supplier believes the POS Equipment is or may be located and may remove the POS Equipment from such premises; and/or</p>
              <p>ii. until the POS Equipment is returned or removed, continue to charge the Merchant, and the Merchant will continue to pay, the applicable Service Fees for the rental of the POS Equipment.</p>
            </div>
          </div>
          <h3 className='mt30'>8. Third Party Broadband Service</h3>
          <div className='plr-30 mt20'>
            <p>(a) This clause 8 applies if the Merchant has selected or agreed to receive, or is receiving, the Third-Party Broadband Service.</p>
            <p>(b) The Merchant acknowledges and agrees that the Supplier is a reseller of this Service, and the Service is subject to the Wireless Nation Terms of Service located at: https://www.wirelessnation.co.nz/terms-of-services/ (Wireless Nation Terms of Service).</p>
            <p>(c The Supplier does not make or give any promises, commitments, representations, warranties or guarantees in relation to this Service beyond or in addition to those expressly set out in the Wireless Nation Terms of Service.</p>
            <p>(d) The Supplier may immediately terminate this Service from the scope of this Agreement if the Supplier's agreement with Wireless Nation Ltd (or its successor) under which the Supplier is authorized to resell this Service terminates or expires for any reason.</p>
          </div>
          <h3 className='mt30'>9. Payments and Billings</h3>
          <div className='plr-30 mt20'>
            <p>(a) In consideration for the Supplier providing the Services to the Merchant in accordance with the terms of this Agreement, the Merchant will pay the Supplier the applicable Service Fees, whether by absorption of the Service Fee or adding it on to the Transaction Value.</p>
            <p>(b）The Supplier may adjust all or any of the Service Fees by giving at least thirty (30) days' prior written notice to the Merchant. If the Supplier gives such a notice, the Merchant may terminate the affected Services from the scope of this Agreement by giving the Supplier written notice prior to the adjusted Service Fees taking effect. Any such termination will take effect 30 days after the Supplier's receipt of the termination notice. If the Merchant does not give notice to terminate prior to the adjusted Service Fees taking effect, the Merchant shall not be entitled to terminate the Services (except, if applicable, in accordance with clause 19 (Term and Termination)) below.</p>
            <p>(c) Service Fees will be invoiced or charged, and will be payable, at the times and in the manner specified in the applicable Schedule. If and to the extent that a Schedule does not specify when a Service Fee will be invoiced, charged and/or payable, the Service Fee will:</p>
            <div className='plr-15'>
              <p>i. be invoiced or charged monthly in arrears; and</p>
              <p>ii. be payable within 14 days of the date of the Supplier's invoice.</p>
            </div>
          </div>
          <h3 className='mt30'>10. Representations and Warranties</h3>
          <div className='plr-30 mt20'>
            <p>Each Party makes the following representations and warranties to the other Party, and acknowledges that such other Party is relying on these representations and warranties in entering into this Agreement:</p>
            <p>(i) The Party: </p>
            <div className='plr-15'>
              <p>a) is an independent corporation duly organized, validly existing and in good standing under the laws of the jurisdiction of its incorporation; </p>
              <p>b) is properly registered to do business in all jurisdictions in which it carries on business; </p>
              <p>c) has all licences, regulatory approvals, permits and powers legally required to conduct its business in each jurisdiction in which it carries on business; </p>
              <p>d) has the corporate power, authority and legal right to execute, deliver and perform this Agreement and to carry out the transactions and its obligations contemplated herein; and</p>
              <p>e) is entering into this Agreement in its own capacity for its own account and is not acting as a nominee or agent of any other third party.</p>
            </div>
            <p>(ii) This Agreement has been duly executed and delivered by the Party and constitutes the valid and binding obligation of the Party, enforceable in accordance with its terms.  Except as otherwise stated in this Agreement, no approval or consent of any person or government department or agency is legally or contractually required to be obtained by the Party in order to enter into this Agreement and perform its obligations.</p>
            <p>(iii) Neither the execution and delivery of this Agreement nor the consummation by the Party of the transactions contemplated herein will (i) conflict with its constitution (or similar corporate document under applicable laws) of the Party or (ii) violate any judgment, decree or order or statute, rule or regulation applicable to the Party.</p>
            <p>(iv) There is no litigation, proceeding or investigation of any nature pending or, to the Party’s knowledge, threatened against of affecting the Party or any of its Affiliates, which would reasonably be expected to have a material adverse effect on its ability to perform its obligations under this Agreement.</p>
            <p>(v) Each Party will comply with laws applicable to the operation of its business and provision of Services under this Agreement.</p>
          </div>
          <h3 className='mt30'>11. Compliance with Law</h3>
          <div className='plr-30 mt20'>
            <p>(a) In order for the Supplier to satisfy its obligations and to comply with the relevant requirements under applicable laws and regulations, the Merchant shall, upon reasonable request by the Supplier, share information (including transaction information) with the Supplier from time to time. The Merchant authorizes the Supplier to present the relevant information to regulatory authorities and cooperative banks for examination and verification as necessary.</p>
            <p>(b) In accordance with its anti-money laundering, anti-fraud, and other compliance policies and practices, the Supplier may impose reasonable limitations and controls on the Merchant's ability to utilize the Services. Such limitations may include but are not limited to, where good cause exists, restricting particular persons from using the Services.  </p>
            <p>(c) The Supplier shall comply with the New Zealand Privacy Act 2020.</p>
          </div>
          <h3 className='mt30'>12. Disclaimer</h3>
          <div className='plr-30 mt20'>
            <p>TO THE EXTENT PERMITTED BY LAW, EXCEPT AS EXPRESSLY SET OUT IN THIS AGREEMENT: (I) THE SUPPLIER MAKES NO WARRANTY TO EITHER THE MERCHANT OR USERS OF THE MERCHANT'S PLATFORM, EXPRESS OR IMPLIED, WITH RESPECT TO THE  SERVICES OR THE POS EQUIPMENT, WHETHER OF MERCHANTABILITY, OR OF FITNESS FOR ANY PARTICULAR PURPOSE, OR OF ANY OTHER TYPE OR KIND WHATSOEVER; AND (II) THE SUPPLIER DOES NOT WARRANT OR GUARANTEE THAT THE SERVICES OR THE POS EQUIPMENT WILL OPERATE WITHOUT ERROR OR INTERRUPTION.</p>
          </div>
          <h3 className='mt30'>13. Indemnification</h3>
          <div className='plr-30 mt20'>
            <p>Each Party shall indemnify the other Party from and against all actions, claims, demands, liabilities, obligations, losses, costs (including, but not limited to legal fees, expenses and penalties) and interest which are not special, consequential or indirect, suffered, incurred or sustained by, or threatened against the indemnified Party arising out of any breach or violation of this Agreement, or any willful neglect, fraud or dishonesty by either Party or any of its employees or agents. Subject to the above, each party will be responsible for any breach of violation of this Agreement by that Party, its employees or agents and indemnifies the other Party in full in such case as set forth.</p>
          </div>
          <h3 className='mt30'>14. Limitation of Liability</h3>
          <div className='plr-30 mt20'>
            <p>(a)	he Parties agree that neither Party shall be liable to the other Party under or in connection with this Agreement (whether in contract, tort or otherwise) for any loss of profits, loss of business, loss of savings or loss of data, or for any special, consequential or indirect loss, damage, cost or expense, which is suffered or incurred by the other Party.</p>
            <p>(b)	The maximum aggregate liability of a Party to the other Party under or in connection with this Agreement (whether in contract, tort or otherwise) in respect of all breaches and events shall not exceed an amount equal to the total Service Fees paid by the Merchant under this Agreement during the first 12 months of this Agreement. </p>
            <p>(c)	No action regardless of form may be brought by a Party against the other Party and or its Affiliates more than one year after the cause of action arose.  </p>
            <p>(d)	The exclusions and limitations in clauses 14(a), 14(b) and 14(c) will not apply to, or have the effect of excluding or limiting liability for:</p>
            <p>(e)	the Merchant's obligations to pay the Service Fees in accordance with this Agreement;</p>
            <p>(f)	the Merchant's obligations to comply with the Tap Touch User Terms and Conditions;</p>
            <p>(g)	the Merchant's obligations to pay the cost of any lost or damaged POS Equipment;</p>
            <p>(h)	claims by third parties which relate to Products; or</p>
            <p>(i)	any breach relating to, or misuse of, a Party's Intellectual Property or Confidential Information</p>
          </div>
          <h3 className='mt30'>15. Tax</h3>
          <div className='plr-30 mt20'>
            <p>All Taxes arising from the implementation hereof shall be for account of and the liability of the respective Party according to the prevailing taxation provisions, unless agreed otherwise in writing by the Parties. Notwithstanding anything else contained herein, all amounts payable by the Merchant to the Supplier under this Agreement shall be free of withholding on account of any Taxes imposed by the relevant taxation authority of the applicable jurisdictions which the Merchant is subject to. Unless expressly stated otherwise, all amounts stated in this Agreement are stated exclusive of GST. GST on any amount charged under this Agreement must be paid at the same time as that amount.</p>
          </div>
          <h3 className='mt30'>16. Confidential Information and Intellectual Property</h3>
          <div className='plr-30 mt20'>
            <p>(a) Each Party acknowledges that the Confidential Information of the other is valuable to it and agrees to treat as confidential all Confidential Information received from the other Party in connection with the Services. Neither Party shall disclose such information except to perform its obligations under this Agreement or as required by law or regulations, and in the event such disclosure is required by law, the disclosing Party shall give the other Party prior notice of such disclosure except that a Party does not need to provide such notice where such Party is prohibited by applicable law from providing such notice.   Upon termination of this Agreement and the written request of the other Party, each Party shall promptly return or destroy all tangible material embodying Confidential Information of the other.  </p>
            <p>(b) “Confidential Information” means all non-public, proprietary or other confidential information, whether in oral, written or other form, including but not limited to: the content and performance of this Agreement, business plans, capitalization tables, budgets, financial statements; costs, prices, and marketing plans, contracts and licenses, employee, customer, supplier, shareholder, partner or investor lists, technology, know-how, business processes, trade secrets and business models, notes, sketches, flow charts, formulas, blueprints, and elements thereof, and source code, object code, graphical design, user interfaces and other intellectual property, including that of any customer, supplier or other third party (including the interface technologies, security protocol and certificate to any other website or enterprise provided by the Supplier).</p>
            <p>(c) Each Party will retain ownership of its Intellectual Property as at the Commencement Date and any other Intellectual Property which that Party creates or acquires independently of this Agreement.</p>
            <p>(d) Any new Intellectual Property which the Merchant creates under or in connection with this Agreement which specifically relates to the Products or the Merchant's own brands or trademarks will vest in, and be owned by, the Merchant. All other new Intellectual Property created by either Party under or in connection with this Agreement will vest in, and be owned by, the Supplier.</p>
          </div>
          <h3 className='mt30'>17. Publicity</h3>
          <div className='plr-30 mt20'>
            <p>Neither Party shall issue any press release or make any public announcement pertaining to this Agreement without the prior written consent of the other Party unless required by applicable laws binding the Party.  However, the preceding limitation shall not be interpreted to prevent the Supplier from making statements about the Supplier's aggregate business or about the Supplier's Services in general in or outside of the jurisdiction where the Merchant is located.</p>
          </div>
          <h3 className='mt30'>18. Notice</h3>
          <div className='plr-30 mt20'>
            <p>(a) Other than business correspondence in relation to the ordinary operation of the Supplier's Services, all notices and other communications required or permitted between the Parties under this Agreement (including changing any terms of this Agreement) will be in writing and shall be sent by express courier or email to the contact details as provided by the Parties.  Notice shall be effective upon receipt.  A rebuttable presumption of receipt will be created: </p>
            <p>(b) if notice is sent by express courier, by the courier’s recording delivery; and </p>
            <p>(c) if notice is sent by email, by the sending Party’s possession of a confirmation of successful transmission.  </p>
            <p>(d) Notice given in any other manner will be rebuttably presumed not to have been given unless acknowledged in writing by the Party receiving the notice.  </p>
            <p>(e) Unless otherwise by sending a notice in accordance with this paragraph, all notices to the Merchant and Supplier shall be addressed as specified in the Merchant Information section or Supplier Information section of this Agreement (as applicable).  </p>
            <p>(f) For the avoidance of doubt, except as expressly written in this Agreement, emails, including emails with electronic signature blocks containing the sender’s name, do not constitute signed written agreements and will not amend this Agreement.</p>
          </div>
          <h3 className='mt30'>19. Term and Termination</h3>
          <div className='plr-30 mt20'>
            <p>(a) This Agreement commences on the Commencement Date will continue indefinitely until terminated in accordance with this Agreement.</p>
            <p>(b) Either Party may terminate this Agreement at any time following all applicable Minimum Service Terms having expired by giving at least 30 days' prior written notice to the other Party.</p>
            <p>(c) Either Party may terminate a Service from the scope of this Agreement at any time following the applicable Minimum Service Term for that Service by giving at least 30 days' prior written notice to the other Party.</p>
            <p>(d) Notwithstanding any other provision of this Agreement to the contrary, the Supplier may terminate this Agreement or a Service from the scope of this Agreement immediately, if the Supplier determines, in its sole discretion, that compliance with this Agreement would cause the Supplier or any of its Affiliates to violate or potentially violate any law, contract or compliance policy of the Supplier or any of its Affiliates.  </p>
            <p>(e) A Party may terminate this Agreement or a Service from the scope of this Agreement with immediate effect by giving written notice to the other Party if </p>
            <div className='plr-15'>
              <p>i. the other Party commits a breach of this Agreement (other than a trivial breach which has no discernible adverse effect on the first Party) which is not capable of remedy or is not remedied within 14 days of the other Party receiving notice of the breach; or</p>
              <p>ii. the other Party goes into liquidation, is wound-up or dissolved or is declared bankrupt, or a receiver, administrator or statutory manager is appointed in respect of the other Party or any of its assets, or the other Party becomes subject to any event or proceeding analogous in nature to any of the preceding events or circumstances under the laws of any relevant jurisdiction.</p>
            </div>
            <p>(f) If a Service is terminated from the scope of this Agreement, this Agreement will:</p>
            <div className='plr-15'>
              <p>i. be deemed to be amended by removing the Service and the associated Service Fees from the scope of this Agreement; and</p>
              <p>ii. otherwise remain in full force and effect.</p>
            </div>
            <p>(g) Where this Agreement or a Service is terminated:</p>
            <div className='plr-15'>
              <p>i. the Merchant must pay all Service Fees for the period up to the effective date of termination;</p>
              <p>ii. the termination will be without prejudice to a Party's rights or remedies in respect of any breach of this Agreement which occurred prior to the effective date of termination.</p>
            </div>
            <p>(h) Provisions of this Agreement which by their nature are intended to survive termination, including (without limitation), clause 7(l) (Return of POS Equipment), clause 12 (Disclaimer), clause 13 (Indemnification), clause 14 (Limitation of Liability), clause 15 (Taxes), clause 16 (Confidential Information and Intellectual Property), clause 17 (Publicity), clauses 19(g) and (h), and clause 21 (Governing Law/Dispute Resolution), will remain in full force and effect following the termination of this Agreement.</p>
            <p>(i) The Supplier may suspend the provision of all or any of the Services if the Merchant has, or the Supplier reasonably suspects that the Merchant has, breached any material term of this Agreement or any applicable law. The Supplier will lift any such suspension and resume the provision of the Service(s) (unless the Supplier elects to instead terminate the Service(s) or this Agreement) as soon as reasonably practicable following the Supplier having completed its investigations and the Merchant having remedied all applicable breaches. Unless the Supplier agrees otherwise, all applicable Service Fees will continue to be payable during any suspension.</p>
          </div>
          <h3 className='mt30'>20. Force Majeure</h3>
          <div className='plr-30 mt20'>
            <p>Neither Party will be liable for any failure or delay in its performance under this Agreement due to any cause beyond its reasonable control, including but not limited to, a Force Majeure Event. Payment obligations cease while any Services are suspended. A “Force Majeure Event” means an event that beyond the reasonable control of the affected Party other than due to its fault or negligence, which includes, without limitation to, acts of god, fire, computer virus, defect in design of instrumental software, attack by hacker, change in laws or policies, major outages of a telecommunication carrier’s network connections, interface incidents of partner banks and financial institutions, gateway incidents of mobile carriers, unexpected incidents resulting from changes in users’ systems, unexpectedly large increases in traffic volume as a direct result of any orders of a governmental authority, and governmental authority intervention that results in the seizure or confiscation of Party’s systems, in each case to the extent used in or necessary for the provision of the services and only to the extent such event(s) are beyond the control of the affected Party and only for as long as such event(s) persist.</p>
          </div>
          <h3 className='mt30'>21. Governing Law/Dispute Resolution</h3>
          <div className='plr-30 mt20'>
            <p>This Agreement shall be governed by and construed under New Zealand law, without regard to principles of conflict of laws thereunder.  If any dispute arises between the parties concerning this Agreement (including its breach, validity or termination), then, except as expressly provided in this Agreement, the Parties shall in good faith endeavour to resolve the dispute by consultation and negotiation between them or by using appropriate dispute resolution techniques, but without prejudice to any other right or entitlement they may have pursuant to this Agreement or otherwise.</p>
            <p>If a dispute is not resolved within 14 days of written notice by one Party to the other of the dispute (or such further period agreed in writing between them), either Party may refer the dispute to the arbitration of a single arbitrator. The arbitrator shall be agreed between the parties within 14 days of written notice of referral by the referring Party to the other or, failing agreement, shall be appointed by the President for the time being of the New Zealand Law Society.  In either case, the arbitrator shall not be a person who has participated in any informal dispute resolution procedure in respect of the dispute.  Other than as provided in this clause, the arbitration shall be conducted in accordance with the provisions of the Arbitration Act 1996 and the parties expressly include the provisions of the Second Schedule of that Act and reserve the right of appeal to the High Court on any question of law arising out of an award. The arbitrator's costs of the arbitration shall be shared equally between the Parties.</p>
          </div>
          <h3 className='mt30'>22. Costs and Expenses</h3>
          <div className='plr-30 mt20'>
            <p>Except as expressly set forth in this Agreement, each Party shall be solely responsible for all costs and expenses incurred by it in connection with the preparation and negotiation of this Agreement and the provision or receipt of the Services.</p>
          </div>
          <h3 className='mt30'>23. Entire Agreement</h3>
          <div className='plr-30 mt20'>
            <p>This Agreement and all schedules and appendices hereto and thereto, is the entire agreement between the Parties with respect to the subject matter and supersedes any previous agreements and understandings, provided that:</p>
            <p>(a) nothing in this Agreement will affect the application of, or have the effect of contracting out of, the Fair-Trading Act 1986; and   </p>
            <p>(b) nothing in this Agreement will preclude a Party from (subject to the other provisions of this Agreement) seeking relief under Subpart 3 of Part 2 of the Contract and Commercial Law Act 2017 in relation to any misrepresentation made by the other Party which induced the first Party to enter into this Agreement.</p>
          </div>
          <h3 className='mt30'>24. Amendments</h3>
          <div className='plr-30 mt20'>
            <p>(a) The Supplier may amend or vary this Agreement from time to time by giving at least 30 days' prior notice in writing to the Merchant of such amendment or variation. If the Supplier gives such a notice, the Merchant may terminate this Agreement by giving the Supplier written notice prior to the amendment taking effect. Any such termination will take effect 30 days after the Supplier's receipt of the termination notice. If the Merchant does not give notice to terminate prior to the amendment taking effect, the Merchant shall not be entitled to terminate this Agreement (except, if applicable, in accordance with clause 19 (Term and Termination)) above.</p>
            <p>(b) Except as otherwise expressly written in this Agreement, this Agreement can be changed only by a written agreement signed by all Parties.  Emails, including emails with electronic signature blocks containing the sender’s name, do not constitute signed written agreements and will not amend this Agreement.</p>
          </div>
          <h3 className='mt30'>25. Assignment</h3>
          <div className='plr-30 mt20'>
            <p>Except as set out in this Agreement, this Agreement and the rights and obligations of any Party to this Agreement may not be assigned, transferred, subcontracted, or delegated by operation of law or otherwise.</p>
          </div>
          <h3 className='mt30'>26. No Implied Waiver</h3>
          <div className='plr-30 mt20'>
            <p>The waiver by either Party of a breach or default of any provision of this Agreement by the other Party, or the failure on the part of either Party to exercise any right or privilege hereunder, shall not be construed as a waiver of any subsequent breach or default by the other Party, or as a waiver of any such provision, right, or privilege hereunder.</p>
          </div>
          <h3 className='mt30'>27. Relationship</h3>
          <div className='plr-30 mt20'>
            <p>Nothing contained in this Agreement shall be construed as creating a joint venture, partnership, agency, fiduciary, or employment relationship among or between any of the Parties.</p>
          </div>
          <h3 className='mt30'>28. Severability</h3>
          <div className='plr-30 mt20'>
            <p>If any provision of this Agreement is not enforceable, the remaining provisions remain valid and enforceable.</p>
          </div>
          <h3 className='mt30'>29. Subcontracting</h3>
          <div className='plr-30 mt20'>
            <p>The Supplier may delegate the satisfaction of its obligations under this Agreement to any of its Affiliates or other subcontractors, provided that the Supplier remains responsible to the Merchant for the performance of its obligations under the Agreement.</p>
          </div>
          <h3 className='mt30'>30. General</h3>
          <div className='plr-30 mt20'>
            <p>This Agreement may be executed in any number of counterparts and all the counterparts when taken together will constitute one agreement. Each party may enter into this Agreement by executing a counterpart. The parties acknowledge that this Agreement may be executed by an exchange of facsimile and/or scanned and emailed PDF copies and execution of this Agreement by that means is valid and sufficient execution.</p>
          </div>

          <hr className='mt50'/>

          <h2 className="mt50 font-center">APPENDIX 1</h2>
          <h2 className="mt20 font-center">PROHIBITED AND RESTRICTED PRODUCT LIST</h2>
          <div className='plr-30 mt20'>
            <p>Illegal political audio-visual products and publications</p>
            <p>Illegal reactionary cards and program channels</p>
            <p>State secret documentations and information, etc.</p>
            <p>Other reactionary articles and speeches, etc.</p>
            <p>Pornographic and vulgar audio-visual products/publications</p>
            <p>Pornographic and vulgar erotic services</p>
            <p>Pornographic and vulgar cards and program channels</p>
            <p>Other pornographic and vulgar articles or services</p>
            <p>Gambling tools</p>
            <p>Private lottery</p>
            <p>Gambling/gaming service</p>
            <p>Drugs</p>
            <p>Drug-taking tools</p>
            <p>All kinds of weapons (including military weapons/firearms and accessories), simulation weapons, ammunitions and explosive</p>
            <p>Controlled instruments</p>
            <p>Crime articles</p>
            <p>Poisonous articles and hazardous chemicals</p>
            <p>Narcotics and psychotropic drugs</p>
            <p>Toxic drugs</p>
            <p>Fetal gender diagnosis</p>
            <p>Adult drugs (aphrodisiac)</p>
            <p>Credit speculation service (including speculation of Taobao credit rating)</p>
            <p>Credit card cashing service</p>
            <p>Foreign-related matchmaking service</p>
            <p>Hacking-related</p>
            <p>Malware</p>
            <p>Other software services which jeopardise Alibaba and the subsidiaries of Alibaba</p>
            <p>Certificate issuing and stamp carving</p>
            <p>Crowd funding websites</p>
            <p>High-risk services</p>
            <p>ID card information and other information which infringes others' privacy</p>
            <p>Spying instruments</p>
            <p>Other personal privacy-harming articles or services</p>
            <p>Pyramid selling</p>
            <p>Lottery ticket</p>
            <p>Gold futures</p>
            <p>Counterfeit currency</p>
            <p>Bank account transaction (bank cards)</p>
            <p>Stock</p>
            <p>Fund</p>
            <p>Insurance</p>
            <p>Insurance platform</p>
            <p>Periodical investment of gold</p>
            <p>Bank financial products</p>
            <p>Cashback services</p>
            <p>Single-purpose prepaid cards</p>
            <p>Securities</p>
            <p>Illegal fund-raising</p>
            <p>Foreign exchange services</p>
            <p>Virtual currency in foreign accounts</p>
            <p>Receipts (invoices)</p>
            <p>Bitcoin, Litecoin, YBcoin and other virtual currency transactions</p>
            <p>MCard, etc.</p>
            <p>Satellite antenna, etc.</p>
            <p>Archaeological and cultural relics</p>
            <p>Items and services in violation of relevant state regulations</p>
            <p>Poor quality (fake) food</p>
            <p>Tobacco</p>
            <p>Fireworks and firecrackers</p>
            <p>Crude oil</p>
            <p>Charity</p>
            <p>Human organs</p>
            <p>Surrogacy services</p>
            <p>Examination services</p>
            <p>National protected animals</p>
            <p>National protected plants</p>
            <p>Smuggled articles</p>
            <p>Special provisions for specific periods of time (e.g. the Olympic Games)</p>
            <p>Medical devices</p>
            <p>Drugs</p>
            <p>Contact lens</p>
            <p>Auction</p>
            <p>Pawn</p>
            <p>Payment institutions</p>
            <p>Circulating RMB</p>
            <p>Foreign currency</p>
            <p>Cultural relics</p>
            <p>Video chatting services</p>
            <p>Religious websites</p>
            <p>Online cemetery and worshipping and other services</p>
            <p>Computer privacy information monitoring</p>
            <p>Lucky draw</p>
            <p>Any animals, plants or products which contain dangerous germs, pests or any other living creature</p>
            <p>Any products, medicine or any other article which originate from epidemic area of infectious disease which causes threat to health of human beings or animals</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
