import TopRow from './TopRow';
import NavBar from './NavBar';


export default function Header() {
  return (
    <div>
      <TopRow></TopRow>
      <NavBar></NavBar>
    </div>
  );
}
