import './services.css'
import News1 from '../static/img/newssmall1.png'
import News2 from '../static/img/newssmall2.jpeg'
import { Link } from 'react-router-dom'


export default function ServiceRight() {
  return (
    <div>
      <div className='oursevice-wrapper mb30'>
              <p className='oursevice-wrapper-title mb30'>Our Services & Products</p>
              <div className='oursevice-list-wrapper'>
                <ul>
                 <Link to='/services-products/ie-pay/'> <li>IE PAY</li></Link>
                  <Link to='/services-products/ie-mall/'><li>IE MAll</li></Link>
                  <Link to='/services-products/smart-box/'><li>Smart Box</li></Link>
                </ul>
              </div>
            </div>
            <div className='oursevice-wrapper'>
              <p className='oursevice-wrapper-title mb30'>Latest News</p>
              <div className='oursevice-list-wrapper'>
                <Link to='/news/recover-from-covid-19'>
                  <div className='news fsb pb-20'>
                      <img src={News1} alt="" />
                      <div>
                        <span className='desc'>Listing positions MIEPAY to help companies recover from Covid-19</span>
                        <div className='date'>02 Jun 2020</div>
                      </div>
                  </div>
                </Link>
                <Link to='/news/smart-box'>
                  <div className='news fsb pb-20'>
                    <img src={News2} alt="" />
                    <div>
                      <span className='desc'>MyPay Lauches QR-code payment solution Smart Box in New Zealand</span>
                      <div className='date'>01 Oct 2019</div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
    </div>
  )
}
