import Bread from "../components/bread";
import '../components/services.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMap from '../components/map'
import Footer from "../components/footer";

export default function ContactUs() {
  return (
    <div>
      <Bread />
      <div className='service-container'>
        <div className='contact-content' style={{flexDirection:'column' }}>
          <span className='font12 f500 text-center w100'>GET IN TOUCH</span>
          <span style={{fontSize:'36px'}} className='bfont w100 text-center pb-30'>We love to hear from you</span>
          <div className='w50 pb-60'>
            <p className='text-center regular-font'>Subscribe to our newsletters, call us to book a meetup or send us emails to request a service consultation.</p>
          </div>
          <div className='frowcol w100'>
            <div className='w2-5 contact-left'>
              <div className='contact-unit contact-border-bottom regular-font'>
                    <FontAwesomeIcon className='contact-icon' icon="fa-solid fa-earth-oceania" />
                  <div>
                    <p className='contactus-unit-title'>Location</p>
                <p >Level 10, 420 George Street,<br/>Sydney, NSW 2000</p>
                  </div>
              </div>
              <div className='contact-unit contact-border-bottom regular-font'>
                
                <FontAwesomeIcon className='contact-icon'  icon="fa-solid fa-headset" />
                <div>
                <p className='contactus-unit-title'>Call support</p>
                <p > Australia: +61-2-8034-0470<br/>
                  New Zealand: +64-9-6006545</p>
                  </div>
              </div>
              <div style={{margin: 0}} className='contact-unit regular-font'>
                <FontAwesomeIcon className='contact-icon' icon="fa-solid fa-envelope-square" />
                <div>
                <p className='contactus-unit-title'>Email us</p>
                <p>info@miepay.com</p>
                </div>
              </div>
              <div className='contact-linked-in-background'>
                            <a href='https://cn.linkedin.com/company/mypay-new-zealand-limited' target="_blank"><FontAwesomeIcon icon="fa-brands fa-linkedin" className='contact-linked-in'/></a>
                        </div>
            </div>
            <div className='w3-5 google-map'>
              <GoogleMap></GoogleMap>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
