import Bread from "../components/bread";
import '../components/services.css'
import { Link } from 'react-router-dom'
import ServiceRight from '../components/serversright'
import Iemall1 from '../static/img/iemall1.jpeg'
import Iemall2 from '../static/img/iemall2.png'
import Iemall3 from '../static/img/iemall3.png'


import Footer from "../components/footer";

export default function Iemall() {
  return (
    <div>
      <Bread />
      <div className='service-container'>
        <div className="service-content">
          <div className='left'>
            <div className='left-inner'>
              <p className='p10 mb1r'>IE MALL is an e-Commerce aggregation that provides cross-border customers with a wide range of quality or boutique products made in Australia and New Zealand, with comprehensive support services, which is customisable at a low cost.</p>
              <div className='p10 flex'>
                <div className='w1-3 p10'>
                  <p style={{fontSize: '1.5rem'}} className='service-title'>Sell — Everywhere</p>
                  <p className='iemall-desc'>
                    Use one platform to sell products to anyone, anywhere—online with your ecommerce store, online marketplaces and social media.
                  </p>
                  <p className='iemall-desc'>
                     Attract international consumers who are looking to purchase quality or boutique products made in Australia and New Zealand.
                  </p>
                </div>
                <div className='w2-3 p10'>
                  <img style={{width: '100%'}} src={Iemall1} alt="" />
                </div>
              </div>
              <div className='p10 flex'>
                <div className='w1-3 p10'>
                  <p style={{fontSize: '1.5rem'}} className='service-title'>Manage — Everything</p>
                  <p className='iemall-desc'>
                    Use multiple dashboards to manage orders, shipping and payments anywhere you go. Gain the insights and knowledge you need to grow.
                  </p>
                </div>
                <div className='w2-3 p10'>
                  <img style={{width: '100%'}} src={Iemall2} alt="" />
                </div>
              </div>
            <div className='p10'>
              <img style={{width:'100%'}} src={Iemall3} alt="" />
            </div>
            <div className=''>
              <div className='mt30'>
                <p className='service-title' style={{fontSize: '1.5rem'}}>Market — Your business</p>
                <p className='mt30'>We actively grow our audience through online channels, including paid search, organic search and social media. Our offline channel strategy includes participating in trade shows and local events to generate awareness of our platform.</p>
              </div>
            </div>

            <div className='mt30'>
              <a href='https://www.iemall.co.nz/' target="_blank" className='learn-btn'>LEARN MORE</a>
            </div>
            </div>
          </div>
          <div className='right'>
            <ServiceRight></ServiceRight>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
