import { Row, Col, Divider, List, Avatar } from 'antd';
import Bread from "../../components/bread";
import NewsCard from '../../components/newsCard'
import NewsImgOne from '../../static/img/recover-from-covid-19.png';
import Footer from "../../components/footer";

const data = [
  {
    title: 'Listing positions MIEPAY to help companies recover from Covid-19',
    date: '02 Jun 2020',
    img: require('../../static/img/recover-from-covid-19.png'),
    url: '/news/recover-from-covid-19'
  },
  {
    title: 'MyPay Lauches QR-code payment solution Smart Box in New Zealand',
    date: '01 Oct 2019',
    img: require('../../static/img/smart-box.jpeg'),
    url: '/news/smart-box'
  }
];

export default function NewsRecoverFromCovid() {
  return (
    <main>
      <Bread />
      <div className="news-content news-sigle-page">
        <div className="content">
          <Row gutter={32}>
            <Col md={16} sm={24}>
              <img alt="recover-from-covid-19" src={NewsImgOne} className="news-img" />
              <div className="news-detail">
                <p className="news-date">Posted on June 2, 2020</p>
                <Divider />
                <p>New Zealand fintech MIEPAY wants to help small businesses on both sides of the Tasman bounce out of the Covid-19 crisis by enabling them to make the most of the continued rapid growth in e-commerce and digital payments, says MIEPAY chairman Roger Kerr.</p>
                <p>MIEPAY recently listed on Australia’s National Stock Exchange, raising $A3.3 million in an initial public offering.</p>
                <p>Mr Kerr said the money raised would largely be used to fund the growth and development of MIEPAY’s two business units, digital payment solutions provider IE Pay and e-commerce platform IE MALL.</p>
                <p>Mr Kerr said that with both countries making great progress towards containing the Covid-19 virus, now was the ideal time for MIEPAY to go public.</p>
                <p>“While we can’t necessarily say how individual industries – let alone specific companies – are going to fare in the coming months, we can expect economic activity to pick up as restrictions around travel and retail trading ease, and we can certainly say that digital payments and e-commerce are very likely to make up an ever-increasing share of that economic activity,” said Mr Kerr.</p>
                <p>“We want to be ready to take advantage of that, and we want to make sure small businesses both here and in Australia have the necessary tools to optimise their sales and profits.”</p>
                <p>Mr Kerr said over recent years consumers had been increasingly opting for the convenience and security of a digital wallet over a credit card or cash. However, many small businesses on both sides of the Tasman were yet to capitalise on this trend.</p>
                <p>“More than half of businesses here and in Australia are still generating less than 10 percent of their revenue through digital payment services: getting those businesses to engage more fully with the digital economy creates huge opportunities for us and for them,” said Mr Kerr.</p>
                <p>“As a merchant, you want to make it as easy as possible for your customers to do business with you using whatever means of payment they want. With IE PAY, you can do that without the inconvenience and expense of setting up merchant accounts with various providers,” said Mr Kerr.</p>
                <p>MIEPAY Managing Director Andy Zhang said despite IE PAY’s revenue falling sharply during April, as New Zealand went into lockdown, earnings for the three months ending 30 April were up 34 percent on the same period a year ago, and he was confident business activity would continue to increase as restrictions eased further.</p>
                <p>Mr Zhang said the same period had seen a big jump in the number of businesses opening online stores in the IE MALL, as merchants looking for new ways to connect with customers took advantage of IE MALL’s range of services, which include inventory management and marketing support.</p>
                <p>“The IE MALL gives small businesses direct access to consumers around the world and, through its integration with IE PAY, ensures those businesses get their money swiftly, safely, and cheaply,” said Mr Zhang.</p>
                <p><b>About MIEPAY LTD</b></p>
                <ul>
                  <li>MIEPAY’s (NSX:MIE) business model is based on two separate but complementary components: an aggregated payment platform (IEPAY), which delivers low-fee, fast settlement, secure cross-border and domestic transactions and a compelling ecommerce offering for SME producers and merchants (IE MALL).</li>
                  <li>In 2016, IE PAY became the first New Zealand payment solutions provider to sign a partnership with Alipay; the company has since concluded similar agreements with WeChat Pay, POLiPay and UnionPay.</li>
                  <li>IE PAY’s customised mobile payment solutions are accepted by more than 1,100 merchants New Zealand wide, including Green Cross Health (NZX: GXH), which operates the Unichem and Life pharmacy chains; Auckland-based digital gifting company Gifticon;</li>
                  <li>IE MALL gives SME producers and merchants direct access to consumers around the world. Its integration with IE PAY ensures businesses trading through IE MALL receive payment swiftly and securely, and provides a complementary revenue stream for MIEPAY’s aggregated payment platform.</li>
                </ul>
                <p><b>For further information, please contact</b></p>
                <p>Roger J Kerr</p>
                <p>Chairman</p>
                <p>M: +64 21 935 288</p>
                <p>E: rogerjkerr@miepay.com</p>
              </div>
            </Col>
            <Col md={8} sm={24} className="col-right">
              <div className="latest-post">
                <p className="title">Latest Post</p>
                <Divider />
                <List
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={item.img} />}
                        title={<a href={item.url}>{item.title}</a>}
                        description={item.date}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Col>
          </Row>
          <div className="news-card-list">
            <p className="title">You might also like</p>
            <NewsCard />
          </div>
        </div>
      </div>

      <Footer />
    </main>
  );
}