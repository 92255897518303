import Bread from "../components/bread";
import Footer from "../components/footer";
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom'

export default function ProspectusDisclaimer() {
  return (
    <main>
      <Bread />

      <div className="prospectus-disclaimer">
        <div className="content">
          <p>You must read the statement before making an attempt to download a copy of prospectus dated 12 December 2019.</p>
          <p>Due to legal restrictions, access to this prospectus is only available to residents of Australia or New Zealand from within Australia or New Zealand.</p>
          <p>By proceeding you confirm that you are a resident of Australia or New Zealand accessing this website from within Australia or New Zealand and you represent, warrant and agree that:</p>
          <ul>
            <li>you are not a “U.S. person” (as defined in Regulation S under the United States Securities Act of 1933) nor are you acting for the account or benefit of a U.S. person;</li>
            <li>you will not make a copy of the prospectus available to, or distribute a copy of the prospectus to, any such “U.S. person”;</li>
          </ul>
          <p className="title">Terms and Conditions</p>
          <p>MIE Pay Limited has lodged a Prospectus dated 12 December 2019 (Prospectus) with the Australian Securities and Investments Commission (ASIC), a copy of which is available on this website.</p>
          <p>Neither ASIC nor NSX Limited takes any responsibility for the contents of the Prospectus or for the merits of the investment to which it relates.</p>
          <p>The Prospectus does not constitute an offer in any place where, or to any person to whom, it would not be lawful to make such an offer. No action has been taken to register or qualify Issuer or the Offer or to otherwise permit an offering of New Shares in any jurisdiction outside Australia or New Zealand.</p>
          <p>The distribution of the Prospectus (including an electronic copy) outside Australia or New Zealand may be restricted by law. If you come into possession of the Prospectus, you should observe any such restrictions and should seek your own advice on those restrictions. Any failure to comply with such restrictions may contravene applicable securities laws.</p>
          <p>Neither the Prospectus nor the New Shares have been, or will be, registered under the United States Securities Act of 1933, as amended (US Securities Act) or the securities laws of any state or other jurisdiction of the United States. The New Shares may not be offered, sold or resold in the United States or to, or for the account or benefit of, a US Person. The Prospectus does not constitute an offer of New Shares in the United States or to any US Persons, or to any person acting for the account or benefit of a US Person.</p>
          <p>You should ensure that any copy of the Prospectus you view or print is complete. To the extent permitted by law, Issuer is not liable for any loss incurred from accessing or downloading Prospectus from this website, including, but not limited to, data corruption on download.</p>
          <p>This notice does not form part of the Prospectus. You must read it before you attempt to access the electronic version of the Prospectus on this website and indicate your agreement or otherwise at the bottom of this notice.</p>
          <Row className="btn-content">
            <Col md={12} sm={24}>
              <Link to="/download-prospectus">
                <Button className="btn" type="primary" size="large">I AGREE</Button>
              </Link>
            </Col>
            <Col md={12} sm={24}>
              <Link to="/investor-relations">
                <Button className="btn" type="primary" danger size="large">I DO NOT AGREE</Button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>

      <Footer />
    </main>
  );
}
