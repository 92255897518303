import Bread from "../components/bread";
import Footer from "../components/footer";
import { Row, Col, Button } from 'antd';

const data = [
  {
    title: 'Download Replacement Prospectus 24 Dec 2019',
    url: 'https://miepay-public.oss-ap-southeast-2.aliyuncs.com/pdf/Prospectus_24_Dec_2019.pdf'
  },
  {
    title: 'Download Supplementary Prospectus 12 Feb 2020',
    url: 'https://miepay-public.oss-ap-southeast-2.aliyuncs.com/pdf/MIE-PAY-LTD-Supplementary-Prospectus.pdf'
  }
];

export default function DownloadProspectus() {
  return (
    <main>
      <Bread />

      <div className="investor-relation">
        <div className="investor-content">
        {
          data.map(item => {
            return  (
              <Row className="item-box">
                <Col md={18} sm={24}>
                  <h2 className="title">{item.title}</h2>
                </Col>
                <Col md={6} sm={24}>
                  <a href={item.url} target="_blank">
                    <Button className="btn" type="primary" size="large">Download</Button>
                  </a>
                </Col>
              </Row>
            )
          })
        }
        </div>
      </div>

      <Footer />
    </main>
  );
}
