import  { Component } from 'react';
import { useNavigate } from "react-router";
import 'moment/locale/zh-cn';


const withRouter = (Component) => {
	const Wrapper = (props) => {
		const history = useNavigate();
		return <Component history={history} {...props} />;
	};
	return Wrapper;
};
class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      // withRoute is delete after react-dom-router v6.0.0
        // if (this.props.location.pathname !== prevProps.location.pathname) {
        //     window.scrollTo(0, 0);
        // }
    }

    render() {
        return this.props.children;
    }
}
export default withRouter(ScrollToTop);
