import App from "./App";
import { createRoot } from 'react-dom/client';
import { BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/home'
import AboutUs from './pages/aboutUs'
import News from './pages/news'
import ContactUs from './pages/contactus';
import Iemall from './pages/iemall';
import Iepay from './pages/iepay';
import Smartbox from './pages/smartbox';
import InvestorRelation from './pages/investorRelation';
import Wechat from './pages/wechat'
import Alipay from './pages/alipay'
import ServicePage from './pages/services'
import CorporateGovernance from './pages/corporateGovernance';
import ProspectusDisclaimer from './pages/prospectusDisclaimer';
import DownloadProspectus from './pages/downloadProspectus';
import NewsRecoverFromCovid from './pages/news/recoverFromCovid';
import NewsSmartbox from './pages/news/smartBox';
import TermsAndConditions from './pages/termsAndConditions';
import ScrollToTop from './components/scrollToTop';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTachometerAlt,
  faAngleLeft,
  faAngleDown,
  faWifi,
  faUmbrella,
  faTasks,
  faLaptop,
  faUsers,
  faCircleDot,
  faDove,
  faBuildingColumns,
  faCalendar,
  faRankingStar,
  faEarthOceania,
  faHeadset,
  faEnvelopeSquare
} from '@fortawesome/free-solid-svg-icons';
import { fab} from "@fortawesome/free-brands-svg-icons";//商标元素 则需要添加前缀
import { far } from "@fortawesome/free-regular-svg-icons";

library.add(
  faTachometerAlt,
  faAngleLeft,
  faAngleDown,
  faWifi,
  fab,
  far,
  faUmbrella,
  faTasks,
  faLaptop,
  faUsers,
  faCircleDot,
  faDove,
  faBuildingColumns,
  faCalendar,
  faRankingStar,
  faEarthOceania,
  faHeadset,
  faEnvelopeSquare
)

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<BrowserRouter>
<ScrollToTop>
<Routes>
      <Route path="/" element={<App />} >
        <Route index element={<Home />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="news" element={<News />} />
        <Route path='services-products'>
          <Route index element={<ServicePage />} />
          <Route path="ie-mall/" element={<Iemall />} />
          <Route path="smart-box/" element={<Smartbox />} />
          <Route path="wechat-pay/" element={<Wechat />} />
          <Route path="alipay/" element={<Alipay/>} />
          <Route path="ie-pay/" element={<Iepay />} />
        </Route>
        <Route path="investor-relations" element={<InvestorRelation />} />
        <Route path="corporate-governance" element={<CorporateGovernance />} />
        <Route path="prospectus-disclaimer" element={<ProspectusDisclaimer />} />
        <Route path="download-prospectus" element={<DownloadProspectus />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="news/recover-from-covid-19" element={<NewsRecoverFromCovid />} />
        <Route path="news/smart-box" element={<NewsSmartbox />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      </Route>
    </Routes>
</ScrollToTop>
</BrowserRouter>)
