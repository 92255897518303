import Bread from "../components/bread";
import NewsCard from '../components/newsCard'
import Footer from "../components/footer";

export default function News() {
  return (
    <main>
      <Bread />

      <div className="news-content">
        <div className="news-card-content">
          <NewsCard />
        </div>
      </div>

      <Footer />
    </main>
  );
}
