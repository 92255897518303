import { Breadcrumb } from "antd";
import { Link, useLocation } from 'react-router-dom';
  
function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
            <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
    );
}

export default function Bread() {

    // root path
    let routes = [{path : '../', breadcrumbName : 'MIE Pay'}];

    let name = '';
    let path = '';

    // current path
    const current = useLocation().pathname;
    let routeArr = current.split(/\//);

    // current bread config
    for(let i = 0; i < routeArr.length; i++)
    {
        if(routeArr[i].length <= 0)
        {
            continue;
        }
        let nameArr = routeArr[i].split(/(?=[A-Z])/);
        let currentPath = nameArr.join("-");
        name = nameArr.join(" ").replace('-', ' ');
        path += '/' + currentPath;

        routes.push({path : path, breadcrumbName : name});
    }

    return (
        <div className="bread">
        <div className="b_title">
        <h1>{name}</h1>
          <div>
              <Breadcrumb separator=">" itemRender={itemRender} routes={routes} />
          </div>
        </div>
    </div>
    );
  }
  