import Bread from "../components/bread";
import Footer from "../components/footer";
import '../Contents.css';
import AbountImg01 from '../static/img/aboutus-01.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timeline } from 'antd';
import NewsCard from '../components/newsCard'

const rocketImg = <FontAwesomeIcon icon="fa-solid fa-dove" className='svg-icon'/>;
const flowerImg = <FontAwesomeIcon icon="fa-solid fa-circle-dot" className='svg-icon'/>;
const groupImg =  <FontAwesomeIcon icon="fa-solid fa-users" className='svg-icon' />;


const bankImg =  <FontAwesomeIcon icon="fa-solid fa-building-columns" className='svg-icon' />;
const calendarImg =  <FontAwesomeIcon icon="fa-solid fa-calendar" className='svg-icon' />;
const rankingImg =  <FontAwesomeIcon icon="fa-solid fa-ranking-star" className='svg-icon' />;

export function SVGCard(props) {
  return (
    <div className="card">
      <div>
        <p className="svg-icon">{props.img}</p>
        <p className="title">{props.title}</p>
        <p className="sub">{props.sub_title}</p>
      </div>
    </div>
  );
}

export function StatusCard(props)
{
  return (
    <div className="stat-card">
      <div>
        <p className="stat-icon">{props.img}</p>
        <p className="title">{props.title}</p>
        <p className="sub">{props.sub_title}</p>
      </div>
    </div>
  );
}

export default function AboutUs() {
  return (
    <main style={{ padding: "0" }}>
      <Bread />
      <div className="container">
        <h5>About MIE Pay</h5>
        <h1>A fintech business building an innovative <br /> financial technology ecosystem</h1>
        <p className="image-center"><img src={AbountImg01}/></p>
        <div className="article">
          <p>The Company is a technology driven payments company that facilitates online and offline commerce between retail merchants and end customers. Its operations are based in Auckland and Sydney.</p>
          <p>It is a fintech business building an innovative financial technology ecosystem, the core of which is an interactive payment platform, that not only delivers an improved shopping and checkout experience, with payments included as a component, it’s also focused on lowering acceptance costs for merchants and aims to supplement the experience with value-added services.</p>
          <p>An e-Commerce SaaS platform IE MALL has also been built providing APP-based commerce and in-APP payments for merchants. This technology is being increasingly embraced providing omni-channel shopping experiences for customers.</p>
        </div>

        <div className="cards">
          <SVGCard img={rocketImg} title="Our Misson" sub_title="To make commerce better for everyone" />
          <SVGCard img={flowerImg}  title="What We Do" sub_title="Providing cross-border and local payment and e-Commerce solutions" />
          <SVGCard img={groupImg}  title="Key Strengths" sub_title="Using a mix of co-located and cloud-based servers" />
        </div>
        <hr />

        <div className="time-line">
          <h5>OUR STORY</h5>
          <h1>Company History & Milestones</h1>
          <div className="line">
            <Timeline>
              <Timeline.Item color="#e94751">
                <p className="title date">4 July 2019</p>
                <p className="title">UnionPay partner</p>
                <p className="desc">MyPay NZ appointed as a UnionPay partner.</p>
              </Timeline.Item>
              <Timeline.Item color="#e94751">
                <p className="title date">19 December 2018</p>
                <p className="title">Spin-Off</p>
                <p className="desc">On 19 December 2018, MIE Pay NZ Limited was incorporated in New Zealand, as a wholly owned subsidiary of the Company and as an interposed entity to hold MyPay NZ Limited, as part of the spin-off of MyPay NZ from IEFS to MIE Pay NZ.</p>
              </Timeline.Item>
              <Timeline.Item color="#e94751">
                <p className="title date">December 2018</p>
                <p className="title">IE MALL</p>
                <p className="desc">IE MALL officially launched e-Commerce cloud platform.</p>
              </Timeline.Item>
              <Timeline.Item color="#e94751">
                <p className="title date">December 2018</p>
                <p className="title">Green Cross</p>
                <p className="desc">New Zealand’s largest pharmacy group Green Cross became a MyPay NZ merchant.</p>
              </Timeline.Item>
              <Timeline.Item color="#e94751">
                <p className="title date">1 November 2018</p>
                <p className="title">MIE Pay NZ</p>
                <p className="desc">The Company was incorporated in New South Wales on 1 November 2018 and is the holding company of MIE Pay NZ Limited (MIE Pay NZ) which in turn holds MyPay New Zealand Limited (MyPay NZ).</p>
              </Timeline.Item>
              <Timeline.Item color="#e94751">
                <p className="title date">26 October 2017</p>
                <p className="title">WeChat partner</p>
                <p className="desc">MyPay NZ appointed as a WeChat partner.</p>
              </Timeline.Item>
              <Timeline.Item color="#e94751">
                <p className="title date">September 2016</p>
                <p className="title">Alipay partner</p>
                <p className="desc">MyPay NZ appointed as Alipay’s first NZ partner.</p>
              </Timeline.Item>
              <Timeline.Item color="#e94751">
                <p className="title date">9 May 2016</p>
                <p className="title">Beginning</p>
                <p className="desc">MyPay NZ was incorporated in New Zealand on 9 May 2016 as part of the IE Financial Services Group to take advantage of the global growth in e-Commerce and the need for an aggregate payments platform in the New Zealand and Australian markets. Its ultimate holding company was IE Financial Services Limited (IEFS).</p>
              </Timeline.Item>
            </Timeline>
          </div>
        </div>

        <hr />

        <div className="cards stat">
          <StatusCard img={bankImg} title="1,100+" sub_title="Merchants" />
          <StatusCard img={calendarImg} title="1,300+" sub_title="Transactions per day" />
          <StatusCard img={groupImg} title="60K+" sub_title="Customers per year" />
          <StatusCard img={rankingImg} title="$60M+" sub_title="NZD in transactions processed per year" />
        </div>
      </div>
      <div className="insights">
        <p className="title">INSIGHTS</p>
        <p className="subtitle">Read Our Latest Articles, Tips & News</p>
        <NewsCard />
      </div>

      <Footer />
    </main>
  );
}
