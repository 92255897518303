import './header.css'
import { Link } from 'react-router-dom'
import LogoIcon from '../static/icon/logo.png'
import { Menu, Dropdown , Space,} from 'antd'
import MenuIcon from '../static/icon/menu.png'
import { useLocation } from 'react-router-dom'

const menu = (
  <Menu
    items={[
      {
        label: <Link to="/">Home</Link>,
        key: '0',
      },
      {
        label: <span>About Us</span>,
        key: '1',
        children: [
          {
            key: '5',
            label:<Link to="/aboutUs">About Us</Link> 
          },
          {
            key: '6',
            label:<Link to="/news">news</Link> 
          }
        ]
      },
      {
        label: <span>Services & Products</span>,
        key: '2',
        children: [
          {
            key: '7',
            label:<Link to="/services-products/ie-pay/">IE PAY</Link> 
          },
          {
            key: '8',
            label:<Link to="/services-products/ie-mall/">IE MALL</Link> 
          },
          {
            key: '9',
            label:<Link to="/services-products/smart-box/">Smart Box</Link> 
          }
        ]
      },
      {
        label: <Link to="/investor-relations">Inverstor Relations</Link>,
        key: '3',
      },
      {
        label: <Link to="/contact-us">Contact Us</Link>,
        key: '4',
      },
    ]}
  />
);

export default function NavBar() {
  const location = useLocation().pathname;

  return (
    <div className='navbar header-wraper horizon-layout height-100' >
      <div className='header-content between-layout'>
        <div className='logo-content'>
          <div className='menu-temp'></div>
          <Link to='/'>
            <img className='logo-img' src={LogoIcon} alt="" />
          </Link>
          <div className='navbar-menu-btn'>
            <Dropdown arrow={false} overlay={menu} trigger={['click']}>
            <a href='/' onClick={e => e.preventDefault()}>
              <Space>
                 <div className='menu-wraper'>
                  <img className='menu-icon' src={MenuIcon} alt="" srcset="" />
                 </div>
              </Space>
              </a>
            </Dropdown> 
          </div>
        </div>
        <ul className='navbar-ul'>
          <Link className={location === '/'? 'isActive':''} to='/'>Home</Link>
          <li>About Us +
            <ul>
              <Link className={location === '/aboutUs'? 'isActive':''}  to="/aboutUs">About Us</Link>
              <Link className={location === '/news'? 'isActive':''} to="/news">news</Link>
            </ul>
          </li>
          <li>Services & Products +
            <ul>
              <Link className={location === '/services-products/ie-pay/'? 'isActive':''} to="/services-products/ie-pay/">IE PAY</Link>
               <Link className={location === '/services-products/ie-mall/'? 'isActive':''} to="/services-products/ie-mall/">IE MALL</Link>
              <Link className={location === '/services-products/smart-box/'? 'isActive':''} to="/services-products/smart-box/">Smart Box</Link>
            </ul>
          </li>
          <Link className={location === '/investor-relations'? 'isActive':''} to="/investor-relations">Inverstor Relations</Link>
          <Link className={location === '/contact-us'? 'isActive':''} to='/contact-us'>Contact Us</Link>
        </ul>
        <div className='blank'></div>
      </div>
    </div>
  )
}
