import { Row, Col, Divider, List, Avatar } from 'antd';
import Bread from "../../components/bread";
import NewsCard from '../../components/newsCard'
import NewsImgTwo from '../../static/img/smart-box.jpeg';
import Footer from "../../components/footer";

const data = [
  {
    title: 'Listing positions MIEPAY to help companies recover from Covid-19',
    date: '02 Jun 2020',
    img: require('../../static/img/recover-from-covid-19.png'),
    url: '/news/recover-from-covid-19'
  },
  {
    title: 'MyPay Lauches QR-code payment solution Smart Box in New Zealand',
    date: '01 Oct 2019',
    img: require('../../static/img/smart-box.jpeg'),
    url: '/news/smart-box'
  }
];

export default function NewsSmartbox() {
  return (
    <main>
      <Bread />
      <div className="news-content news-sigle-page">
        <div className="content">
          <Row gutter={32}>
            <Col md={16} sm={24}>
              <img alt="smart-box" src={NewsImgTwo} className="news-img" />
              <div className="news-detail">
                <p className="news-date">Posted on October 1, 2019</p>
                <Divider />
                <p>Inspiry invented the offline mobile payments solution— Smart Box. The Smart Box is a self-service QR Code payments terminal.</p>
                <p>The Inspiry Smart Boxes were first launched at major retail locations in Australia and New Zealand, and help SMEs to reach global market.</p>
                <p>The Smart Boxes allow customers pay with their smartphones by scanning a QR Code in the Alipay and WeChat app. It can be easily set-up in any environment having access to internet and a power cable. The QR Code scanner and interactive payment keypad become functional after a one minute configuration process.</p>
                <p>Also, the Smart Boxes cost lower than hand-held POS (point of sale) terminals. As a cheaper alternative, these self-service boxes are widely accepted. It is especially the case with small merchants and vendors.</p>
              </div>
            </Col>
            <Col md={8} sm={24} className="col-right">
              <div className="latest-post">
                <p className="title">Latest Post</p>
                <Divider />
                <List
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={item.img} />}
                        title={<a href={item.url}>{item.title}</a>}
                        description={item.date}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Col>
          </Row>
          <div className="news-card-list">
            <p className="title">You might also like</p>
            <NewsCard />
          </div>
        </div>
      </div>

      <Footer />
    </main>
  );
}