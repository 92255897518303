import { Row, Col, Card } from 'antd';
import NewsImgOne from '../static/img/recover-from-covid-19.png';
import NewsImgTwo from '../static/img/smart-box.jpeg';
import { Link } from 'react-router-dom'

const { Meta } = Card;

export default function NewsCard() {
  return (
    <Row gutter={24}>
      <Col md={8} sm={24} className="news-card">
        <Link to="/news/recover-from-covid-19">
          <Card
            hoverable
            cover={
              <img
                alt="recover-from-covid-19"
                src={NewsImgOne}
                className="news-img"
              />
            }
          >
            <Meta
              title="Listing positions MIEPAY to help companies recover from Covid-19"
              description="June 2, 2020"
            />
          </Card>
        </Link>
      </Col>
      <Col md={8} sm={24}>
        <Link to="/news/smart-box">
          <Card
            hoverable
            cover={
              <img
                alt="smart-box"
                src={NewsImgTwo}
                className="news-img"
              />
            }
          >
            <Meta
              title="MyPay Lauches QR-code payment solution Smart Box in New Zealand"
              description="October 2, 2019"
            />
          </Card>
        </Link>
      </Col>
    </Row>
  );
}