import Bread from "../components/bread";
import '../components/services.css'
import FlowImg from '../static/img/iepay_flow.jpeg'
import Wechat1Img from '../static/img/wechat1.png'
import Wechat2Img from '../static/img/wechat2.png'
import Wechat3Img from '../static/img/wechat3.png'
import Wechat4Img from '../static/img/wechat4.png'
import ServiceRight from '../components/serversright'

export default function Wechat() {
  return(
    <div>
      <Bread></Bread>
      <div className='service-container'>
        <div className="service-content">
          <div className='left'>
            <div className='left-inner'>
              <p className='p10 mb1r font125'>WeChat Pay is a payment feature integrated into the WeChat APP, and users can complete payments quickly with smartphones. WeChat has Quick Pay, QR Code Payments, In-APP Web-Based Payments, and Native In-APP Payments, all to realise the full range of scenarios your customers expect to fulfil different payment situations.</p>
              <p className='p10 mb1r font125'>Combined with WeChat official accounts, the WeChat Pay service explores and optimizes the online-to-offline (o2o) consumption experience, and provides professional internet solutions for physical businesses. It is the best choice for mobile payments.</p>
              <p className='p10 mb1r font125'>WeChat is not just a mobile messaging APP. It’s a new lifestyle, connecting people with people, services, devices and more.</p>

              <img className='p10 mb1r' style={{width:'100%'}} src={FlowImg}
             alt="" />
             <p style={{color: '#004098'}} className='service-title'>Multiple Payment Methods</p>
             <div className='flex'>
              <div className='w50 p10'>
                <p style={{color: 'black'}} className='service-title mt-50'>Quick Pay</p>
                <p>Vendors scan the QR Code shown by customers on the Quick Pay page to finish transactions quickly.</p>
              </div>
              <div className='w50 p10'>
                <img style={{width:'100%'}} src={Wechat1Img} alt="" />
              </div>
            </div>
            <div className='flex flex-row-reverse'>
              <div className='w50 p10'>
                <p style={{color: 'black'}} className='service-title mt-50'>QR Code Payment</p>
                <p>Vendors creates different QR codes for different goods. After users scan these codes, they can see related product information and transaction guides on their phone.</p>
              </div>
              <div className='w50 p10'>
                <img style={{width:'100%'}} src={Wechat2Img} alt="" />
              </div>
            </div>
            <div className='flex'>
              <div className='w50 p10'>
                <p style={{color: 'black'}} className='service-title mt-50'>In-APP Web-based Payment</p>
                <p>Vendors push product messages to their followers via Official Account. With WeChat Pay enabled, their followers can purchase products on the shopping page.</p>
              </div>
              <div className='w50 p10'>
                <img style={{width:'100%'}} src={Wechat3Img} alt="" />
              </div>
            </div>
            <div className='flex flex-row-reverse'>
              <div className='w50 p10'>
                <p style={{color: 'black'}} className='service-title mt-50 '>In-APP Payment</p>
                <p>Vendors creates different QR codes for different goods. After users scan these codes, they can see related product information and transaction guides on their phone.</p>
              </div>
              <div className='w50 p10'>
                <img style={{width:'100%'}} src={Wechat4Img} alt="" />
              </div>
            </div>
            </div>
          </div>
          <div className='right'>
            <ServiceRight></ServiceRight>
          </div>
        </div>
      </div>
    </div>
  )
}
